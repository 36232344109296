import React, { useEffect, useState } from 'react';
import { firestore } from '../lib/firebase.prod';
import { collection, query, limit, getDocs, startAfter } from 'firebase/firestore';
import { Button } from '@mantine/core';
import { ArrowBigDownLines } from 'tabler-icons-react';
import { Spinner } from './styles';
import styled from 'styled-components';
import LdsRoller from '../components/loaders/ldsRoller';

export const Dot = styled.span`
  display: list-item;
  color: ${({ color }) => color};
  height: 0;
  z-index: 10;
  margin-left: 0.5rem;
`;

const webColors = {
  resource: '#EE6983',
  company: '#57D1C9',
  dancewebsite: '#D2001A',
  show: '#BE1440',
  school: '#185',
  theater: '#FA2FB5',
  org: '#E6B31E',
  operaHouse: '#007EAA',
};

const data = [
  { value: 'resource', label: 'resource' },
  { value: 'company', label: 'company' },
  { value: 'dancewebsite', label: 'dance website' },
  { value: 'show', label: 'show' },
  { value: 'school', label: 'school' },
  { value: 'theater', label: 'theater' },
  { value: 'org', label: 'org' },
  { value: 'operaHouse', label: 'opera house' },
];

function Websites() {
  const [websites, setWebsites] = useState([]);
  const [cursorDoc, setCursorDoc] = useState(null);
  const [loading, setLoading] = useState(false);

  const websitesQuery = query(collection(firestore, 'websites'), limit(200));

  useEffect(() => {
    setLoading(true);
    getDocs(websitesQuery).then(snapshot => {
      const lastVisible = snapshot.docs[snapshot.docs.length - 1];
      const firstWebsites = snapshot.docs.map(contentObj => ({
        ...contentObj.data(),
        docId: contentObj.id,
      }));

      setCursorDoc(lastVisible);
      setWebsites(firstWebsites);
      setLoading(false);
    });
  }, []);

  async function getMore() {
    setLoading(true);
    const paginatedQuery = query(collection(firestore, 'websites'), startAfter(cursorDoc), limit(200));
    return getDocs(paginatedQuery).then(snapshot => {
      const lastVisible = snapshot.docs[snapshot.docs.length - 1];
      setCursorDoc(lastVisible);

      const paginatedWebsites = snapshot.docs.map(contentObj => ({
        ...contentObj.data(),
        docId: contentObj.id,
      }));
      setWebsites(prev => [...prev, ...paginatedWebsites]);
      setLoading(false);
    });
  }

  if (loading) {
    return (
      <Spinner className="text-center justify-center h-screen flex items-center">
        <LdsRoller />
      </Spinner>
    );
  }

  return (
    <>
      <div className="flex justify-center mt-40 flex-wrap gap-4">
        {data.map(item => {
          // const bgColor = `bg-${item.value}`;

          return (
            <div
              style={{ backgroundColor: webColors[item.value] }}
              key={item.value}
              className={`ml-1 mr-1 pl-4 pr-4 inline-block text-center w-[200] items-center pt-1 pb-1 border-1 rounded-full cursor-pointer text-white`}
            >
              {item.label}
            </div>
          );
        })}
      </div>
      <div className="flex justify-center">
        <div className="mt-20 p-10 flex flex-wrap gap-6 w-3/4">
          {websites.map(({ name, url, type }) => {
            const dotColor = type === 'dance website' ? webColors.dancewebsite : webColors[type];

            return (
              <div
                key={url}
                className="text-xl pl-4 pr-2 pt-2 pb-2 max-w-sm rounded  space-x-2 text-slate-700 hover:bg-slate-100"
              >
                <Dot color={dotColor} />
                <a href={url} className="" target="_blank" rel="noreferrer">
                  {name}
                </a>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex justify-center m-20">
        {websites.length >= 40 && (
          <Button
            onClick={getMore}
            radius={4}
            rightIcon={<ArrowBigDownLines size={14} />}
            disabled={loading}
            className="w-1/2 rounded-md text-slate-100 text-xl h-12 bg-purple-300 hover:bg-gray-100 hover:text-purple-600 font-montserrat"
          >
            Load more
          </Button>
        )}
      </div>
    </>
  );
}

export default Websites;
