import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ARTICLES } from '../articlesData';
import { Spinner } from './styles';
import LdsRoller from '../components/loaders/ldsRoller';

const ArticleCard = ({ title, description, author, slug, tags }) => (
  <div key={title} className="p-4 bg-white rounded shadow-lg text-slate-800 flex flex-col gap-4">
    <Link to={`/dance-articles/${slug}`} aria-label={`Read more about ${title}`}>
      <section className="text-slate-800">
        <h2 className="text-2xl font-bold">{title}</h2>
        <p className="text-lg">{description}</p>
        <small className="font-bold italic">{author}</small>
        {/* Tags */}
        <div className="flex flex-wrap gap-1 mt-4">
          {tags.map(tag => (
            <span key={tag} className="bg-slate-500 text-white rounded-full px-[6px] text-[10px] text-center mx-[1px]">
              {tag}
            </span>
          ))}
        </div>
      </section>
    </Link>
  </div>
);

export default function Articles() {
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setArticles(ARTICLES);
      setLoading(false);
    }, 1000);
  }, []);

  if (loading) {
    return (
      <Spinner className="text-center justify-center h-screen flex items-center">
        <LdsRoller />
      </Spinner>
    );
  }

  if (articles.length === 0 && !loading) {
    return <p>No articles found</p>;
  }

  return (
    <div className="flex justify-center mt-40">
      <div className="p-10 flex gap-4 flex-col w-2/2 md:w-1/2">
        <h1>Dance Articles</h1>
        {articles.map(article => (
          <ArticleCard key={article.title} {...article} />
        ))}
      </div>
    </div>
  );
}
